<nav class="navbar sk-portal-header">
    <div class="container">
        <a href="../"><img class=" " src="https://core.skykick.com/images/svg/skykick-logo.svg" height="28"></a>
    </div>
</nav>
<main>
    <h1 class="text-center display-3 py-5" translate="REGISTRATION.TITLE">Complete Account Setup</h1>

    <div class="container card sk-card">
        <!-- card heading -->
        <div class="row sk-steps p-3">
            <div class="col-sm-12">
                <h2 class="mpn-subheading" translate="REGISTRATION.SUBTITLE">Account Details</h2>
            </div>
        </div>
        <!-- card body -->
        <div class="p-3">
            <div class="row">
                <div class="col-sm-12">
                    <h3 class="my-100" translate="REGISTRATION.DESCRIPTION_TITLE">Microsoft Partner Program Details</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12" translate="REGISTRATION.DESCRIPTION">SkyKick is dedicated to empowering
                    IT Providers to succeed in the cloud and access to our product is limited to Microsoft Solution
                    Providers. You must have a valid Microsoft Partner Network (MPN) ID to continue.</div>
            </div>
            <div class="row mt-100">
                <div class="col-sm-12">
                   <form class="formGroup" [formGroup]="mpnForm" (ngSubmit)="validateMpn()">
                        <label class="form-label" for="sk-mpn">
                            <strong translate="REGISTRATION.MPN">Microsoft Partner Network ID</strong>
                        </label>
                        <div class="input-group">
                            <input id="sk-mpn" formControlName="mpn" class="form-control" type="text" maxlength="10"
                                   (input)="mpnChange()" [disabled]="mpnValidationInProgress">
                            <div class="input-group-append">
                                <button sk-spinner-button id="sk-validate" type="submit" [color]="'primary'"
                                    translate="REGISTRATION.MPN_VALIDATE" [isSpinning]="mpnValidationInProgress"
                                    [disabled]="mpn.invalid || mpnFound || mpnValidationInProgress">Validate</button>
                            </div>
                        </div>

                        <div class="validation-result">
                            <div class="invalid-feedback" *ngIf="mpn.invalid && mpn.dirty"
                                translate="REGISTRATION.ERROR.MPN_INCORRECT_FORMAT">
                                Valid MPN ID must have 6 or 7 digits
                            </div>

                            <div class="invalid-feedback" *ngIf="result === MpnValidationResult.NotFound"
                                translate="REGISTRATION.ERROR.MPN_NOT_FOUND">
                                MPN ID could not be validated
                            </div>
                            
                            <div class="invalid-feedback" *ngIf="result === MpnValidationResult.InUse"
                                translate="REGISTRATION.ERROR.MPN_IN_USE">
                                MPN ID is in use, please contact <a href="https://www.skykick.com/support/get-support#contact_us" target="_blank"><u>SkyKick Support</u></a>
                            </div>
                            <div class="invalid-feedback" *ngIf="result === MpnValidationResult.TooManyAttempts"
                                translate="REGISTRATION.ERROR.MPN_THROTTLED">
                                Too many attempts, please contact <a href="https://www.skykick.com/support/get-support#contact_us" target="_blank"><u>SkyKick Support</u></a>
                            </div>
                            
                            <div class="invalid-feedback" *ngIf="result === MpnValidationResult.ServerError"
                                translate="REGISTRATION.ERROR.SERVER_ERROR">
                                A server error occurred, please try again or contact <a href="https://www.skykick.com/support/get-support#contact_us" target="_blank"><u>SkyKick Support</u></a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row section-under-input">
                <div class="col-sm-12" translate="REGISTRATION.FIND_YOUR_MPN"
                    translate-values="{ url: &quot;https://partner.microsoft.com/en-us/dashboard/account/v3/organization/identity&quot; }">
                    Find your MPN ID in <a
                        href="https://partner.microsoft.com/en-us/dashboard/account/v3/organization/identity"
                        target="_blank">Microsoft Partner Center</a></div>
                <div class="col-sm-12 ng-scope" translate="REGISTRATION.CONNECT_WITH_SKYKICK"
                    translate-values="{ url: &quot;https://register.skykick.com/contactme&quot; }">Not an IT
                    Services Provider? <a href="https://register.skykick.com/contactme" target="_blank">Connect with
                        SkyKick</a></div>
            </div>
            <form class="formGroup" [formGroup]="mpnDetailsForm" (ngSubmit)="submitMpn()">
                <!-- Need to only show this after mpn validated. -->
                <div class="section" [hidden]="!mpnFound">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="d-flex flex-row">
                                <h4 translate="REGISTRATION.MPN_DETAILS_FOUND">MPN Details Found</h4>
                                <i class="material-icons icon-success green-mark">check_circle</i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <fieldset class="col-sm-4"
                            [class.is-invalid]="mpnDetailsForm.get('organizationName').invalid && !mpnDetailsForm.get('organizationName').pristine">
                            <label for="sk-organizationname" class="form-label">
                                <strong translate="REGISTRATION.ORGANIZATION_NAME">Organization Name</strong>
                            </label>
                            <input id="sk-organizationname" formControlName="organizationName" type="text"
                                class="form-control" maxlength="50">
                        </fieldset>
                    </div>
                </div>
                <div class="row py-3">
                    <div class="col-sm-12">
                        <h3 translate="REGISTRATION.PURCHASING_SKYKICK_THROUGH_DISTRIBUTOR">Purchasing
                            SkyKick through a Distributor</h3>

                        <p translate="REGISTRATION.SELECT_DISTRIBUTOR">
                            Select your preferred Distributor in the Settings section of the SkyKick portal under Account.
                        </p>

                        <p translate="REGISTRATION.SEE_DISTRIBUTORS"
                            translate-values="{ url: &quot;https://www.skykick.com/platform/distributors/&quot; }">See a
                            complete list of <a href="https://www.skykick.com/platform/distributors/"
                                target="_blank">SkyKick Distributors</a>.</p>
                    </div>
                </div>
                <div class="row py-3">
                    <div class="d-grid col-sm-12">
                        <button sk-spinner-button id="sk-submit" [color]="'primary'" class="btn-lg px-100" type="submit"
                            translate="REGISTRATION.SUBMIT" [disabled]="!mpnFound" [isSpinning]="mpnRegistrationInProgress">
                            Save and Continue
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</main>
