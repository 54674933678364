import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Utils } from 'src/app/services/utils';

import { Action } from '../models/Action';
import { MpnValidationResult } from '../models/MpnValidationResult';
import { MpnModel } from '../models/MpnModel';
import { MpnCheckService } from '../services/mpn-check.service';
import { PapiHttpService } from '../services/papi-http.service';

@Component({
  selector: 'sk-mpn-registration',
  templateUrl: './mpn-registration.component.html',
  styleUrls: ['./mpn-registration.component.scss']
})
export class MpnRegistrationComponent implements OnInit {

  // Form references. There are two forms on this page.
  mpnForm = new UntypedFormGroup({
    mpn: new UntypedFormControl()
  });
  mpnDetailsForm = new UntypedFormGroup({
    organizationName: new UntypedFormControl()
  });

  // Debugging helper.
  serviceName = "MpnRegistrationComponent";   // even if minified we can log this

  // Form control values.
  mpnValidationInProgress = false;
  mpnRegistrationInProgress = false;
  MpnValidationResult = MpnValidationResult; // this is required to reference enum from HTML
  result = MpnValidationResult.None;
  
  // Main model object.
  model: MpnModel;

  constructor(
    private mpnCheckService: MpnCheckService,
    private papiService: PapiHttpService,
    private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    const self = this;
    this.resetModel();

    // Double check we really should be on this page. This will bring the user to the next step if they don't need to be.
    const sessionMpnRequired = self.mpnCheckService.getMpnRequiredFromSession();
    self.mpnCheckService.performCheck(sessionMpnRequired, true).subscribe(action => {
      // Perform check sets some session variables (mpnRequired, sessionRequired).
      if (action !== Action.GoToAccountSetupPage) {
        self.mpnCheckService.run(action);
      }

      // If we haven't been redirected to the homepage, build the form out.
      self.buildForms()
    });
  }

  resetModel() {
    this.model = {
      mpn: "",
      organizationName: ""
    };
  }

  buildForms() {

    // The MPN form.
    this.mpnForm = this.fb.group({
      mpn: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{6,7}$')])
    });

    // The MPN details form.
    this.mpnDetailsForm = this.fb.group({
      organizationName: [{value: '', disabled: true}]
    });
  }

  // Patch the form with what we get back from PAPI.
  patchForm() {
    // this.mpnDetailsForm.setValue(data); throws an error because there are more fields on the model than the form,
    // so patch the values individually.
    this.mpnDetailsForm.patchValue({
      organizationName: this.model.organizationName
    });
  }

  // Grabs data from the two forms and puts it into the model.
  private setModel() {
    for (const key in this.model) {
      const formValue = this.tryGetFormValue(key);
      // Selectively overwrite parts of the model with what is on the form.
      // The model has extra properties not on the form from an earlier API call.
      if (formValue) {
        this.model[key] = formValue;
      }
    }
    this.debug("setModel", JSON.stringify(this.model));
  }

  // Helper for trying to get the form value equivalents of each item on the model.
  tryGetFormValue(key: string) {
    let result = "";
    let formElement = this.mpnForm.controls[key];
    if (formElement) {
      let value = formElement.value;
      if (value) {
        console.debug(value);
        result = value.trim();
      }
    } else {
      // For example, id is not on the form...
    }
    return result;
  }

  get mpn() {
    return this.mpnForm.controls["mpn"];
  }

  get mpnFound() {
    return this.result === MpnValidationResult.Success;
  }

  // Called whenever the user changes the MPN.
  mpnChange() : void {
    const formValue = this.mpnForm.controls["mpn"].value;
    this.debug("mpnChange", "mpn is now " + formValue);
    this.result = MpnValidationResult.None;
    this.model.mpn = formValue;
  }

  // Check the MPN is valid.
  validateMpn() {
    const self = this;
    self.debug("validateMpn", "enter");

    self.mpnValidationInProgress = true;
    const mpnBeforeRequest = self.model.mpn;
    self.debug("validateMpn", "calling getMpnData(" + this.model.mpn + ")");
    self.papiService.getMpnData(this.model.mpn).subscribe({
      next(response) {
        self.mpnValidationInProgress = false;
        if (response) {
          self.debug("validateMpn", "received a good response from getMpnData");
          // This was taken from PortalV1. After this moment there are more fields on the model than before. For example, id, mpnProfileUrl, lastValidationTime, etc.
          self.model = response;

          // Response comes back with mpnNumber not mpn, that's why this is necessary.
          self.model.mpn = mpnBeforeRequest;

          self.patchForm();

          self.result = MpnValidationResult.Success;
        }
        else {
          self.debug("validateMpn", "received a bad response from getMpnData");

          // User shouldn't be able to get to this state...
          // The form controls that are required are all required, and its disabled on submit,
          // so show a error modal.
          self.mpnCheckService.showGenericErrorModal();

          self.result = MpnValidationResult.ServerError;
        }
      },
      error(err) {
        self.result = self.determineValidationResultFromStatus(err.status);

        self.mpnValidationInProgress = false;
      },
      complete() {
        self.debug("validateMpn", "complete");
      }
    });
  }

  determineValidationResultFromStatus(statusCode: number): MpnValidationResult {
    switch (statusCode) {
      case 404: return MpnValidationResult.NotFound;
      case 409: return MpnValidationResult.InUse;
      case 429: return MpnValidationResult.TooManyAttempts;
      default: return MpnValidationResult.ServerError;
    }
  }

  submitMpn() {
    const self = this;
    self.setModel();
    this.mpnRegistrationInProgress = true;
    self.papiService.saveMpnRegistrationInformation(self.model).subscribe({
      next: response => {
      // We may get back a 204, no content.
        if (!response) {
          self.mpnCheckService.setMpnRequiredInSession(false);
          self.mpnCheckService.goToHomepage();
        }

        this.mpnRegistrationInProgress = false;
      }, 
      error: err => {
        self.mpnCheckService.showGenericErrorModal();
        self.error("submit", err);
        this.mpnRegistrationInProgress = false;
      }
    });
  }

  // Log the error to the console.
  private error(context: string, errorInfo: any) {
    Utils.logError(`${this.serviceName} ERROR in ${context}`, errorInfo);
  }

  // Log debugging info to the console.
  private debug(methodName: string, debugInfo: any) {
    Utils.logDebug(`${this.serviceName}.${methodName}: `, debugInfo);
  }
}
