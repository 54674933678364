import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule, CreditCardModule, FeedbackModule, ModalsModule, StatusSpinnerModule, ToastsModule } from '@skykick/core';

import { ApplyPurePipe } from 'src/app/pipes/apply-pure.pipe';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import { SharedModule } from '../shared/shared.module';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account/account.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { M365AuthenticationComponent } from './authentication/modals/ms365-authentication/m365-authentication.component';
import { MatchUsersStepComponent } from './authentication/modals/ms365-authentication/steps/match-users-step/match-users-step.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { DistributorsComponent } from './distributors/distributors.component';
import {
  AddEditDistributorRelationshipComponent
} from './distributors/modals/add-edit-distributor-relationship/add-edit-distributor-relationship.component';
import { PlatformBrandingComponent } from './platform-branding/platform-branding.component';

@NgModule({
  declarations: [
    AccountComponent,
    CompanyProfileComponent,
    AuthenticationComponent,
    M365AuthenticationComponent,
    PlatformBrandingComponent,
    DistributorsComponent,
    AddEditDistributorRelationshipComponent,
    ApplyPurePipe,
    MatchUsersStepComponent,
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    StatusSpinnerModule,
    ReactiveFormsModule,
    NgbModule,
    ToastsModule,
    TranslateModule.forChild({
      extend: true,
    }),
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    ClipboardModule,
    FontAwesomeModule,
    CreditCardModule,
    SharedModule,
    ButtonsModule,
    ModalsModule,
    FeedbackModule,
  ],
  providers: [NgbModule],
})
export class AccountModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faUpload);
  }
}
